<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      monthly_salary_details: [],
      MonthlySalaryDetails: {},
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchMonthlySalaryDetails: null,
      SearchMode: false,
      emps: [],
      salary_item: [],
      // appprices:[]
    };
  },
  methods: {
    addMonthlySalaryDetails() {
      var data = {
        title: "popups.addMonthlySalaryDetails",
        inputs: [
          {
            model: "emp_id",
            type: "select",
            label: "popups.employee",
            options: (() => {
              return this.emps.map((type) => {
                return { value: type.id, label: type.name };
              });
            })(),
          },

          { model: "type", type: "text", label: "popups.type" },

          {
            model: "salary_item_id",
            type: "select",
            label: "popups.salaryitem",
            options: (() => {
              return this.salary_item.map((salary) => {
                return { value: salary.id, label: salary.name };
              });
            })(),
          },
          { model: "date", type: "date", label: "popups.date" },
          {
            model: "received",
            type: "date",
            label: "popups.received",
          },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              console.log(obj);
              this.http
                .post("monthly-salary-details", {
                  emp_id: obj.emp_id,
                  type: obj.type,
                  salary_item_id: obj.salary_item_id,
                  month: obj.date.split("-")[1],
                  year: obj.date.split("-")[0],
                  received: obj.received,
                })
                .then(() => {
                  this.get(this.page);
                });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editSalary(salary) {
      var data = {
        title: "popups.editMonthlySalaryDetails",
        inputs: [
          {
            model: "emp_id",
            type: "select",
            label: "popups.employee",
            options: (() => {
              return this.emps.map((type) => {
                return { value: type.id, label: type.name };
              });
            })(),
          },

          {
            model: "type",
            type: "text",
            label: "popups.type",
            value: salary.type,
          },

          {
            model: "salary_item_id",
            type: "select",
            label: "popups.salaryitem",
            options: (() => {
              return this.salary_item.map((salary) => {
                return { value: salary.id, label: salary.name };
              });
            })(),
          },
          {
            model: "date",
            type: "date",
            label: "popups.date",
            value: salary.date,
          },
          {
            model: "received",
            type: "date",
            label: "popups.received",
            value: salary.received,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              console.log(obj);

              this.http
                .put("monthly-salary-details", salary.id, {
                  emp_id: obj.emp_id,
                  type: obj.type,
                  salary_item_id: obj.salary_item_id,
                  month: obj.date.split("-")[1],
                  year: obj.date.split("-")[0],
                  received: obj.received,
                })
                .then(() => {
                  this.get(this.page);
                });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.SearchMode = true;

      this.http
        .post("monthly-salary-details/search", {
          search: this.searchMonthlySalaryDetails,
          col: "name",
          limit: this.limit,
          page: this.page,
        })
        .then((res) => {
          var tempData = [];
          for (var i = 0; i < res.data.length; i++) {
            for (
              var j = 0;
              j < res.data[i].monthly_salary_details.length;
              j++
            ) {
              tempData.push(res.data[i].monthly_salary_details[j]);
            }
          }
          this.monthly_salary_details = tempData;
          console.log(this.monthly_salary_details);
        });
    },
    cancelSearchMode() {
      this.searchMonthlySalaryDetails = "";
      this.SearchMode = false;
      this.get(this.page);
    },
    getMonthlySalaryDetails() {
      this.http.get("monthly-salary-details").then((res) => {
        this.monthly_salary_details = res.data;
      });
    },
    deleteMonthlySalaryDetails(salary) {
      var data = {
        title: "popups.deleteMonthlySalaryDetails",
        msg: "popups.deletemsg",
      };
      this.popup.confirm(data).then((res) => {
        if (res) {
          this.http.delete("monthly-salary-details", salary.id).then(() => {
            this.get(this.page);
          });
        }
      });
    },
    getEmp() {
      this.http.get("emps").then((res) => {
        this.emps = res.data;
      });
    },
    getSalaryItem() {
      this.http.get("salary-items").then((res) => {
        this.salary_item = res.data;
      });
    },
    get(page) {
      console.log(page);
      this.http
        .post("monthly-salary-details/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.monthly_salary_details = res.data;
        });
    },
  },
  created() {
    this.get(1);
    // this.getMonthlySalaryDetails();
    this.getEmp();
    this.getSalaryItem();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
      :title="$t('monthlySalaryDetails.title')"
    />
    <div class="row">
      <div class="col-6">
        <div class="search-box chat-search-box" style="width: 250px">
          <div class="position-relative">
            <input
              :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
              @keyup="search()"
              type="text"
              class="form-control fa-lg text-light"
              :placeholder="$t('monthlySalaryDetails.search')"
              v-model="searchMonthlySalaryDetails"
              style="background-color: #2a3042 !important"
            />
            <i class="bx bx-search-alt search-icon text-light"></i>
            <button
              @click="cancelSearchMode()"
              v-if="SearchMode"
              class="btn-close text-light"
              style="position: absolute; top: 12px; left: 250px"
            ></button>
          </div>
        </div>
      </div>
      <div class="col-6" style="display: flex; justify-content: flex-end">
        <button
          type="button"
          @click="addMonthlySalaryDetails()"
          class="btn btn-light float-end mb-4"
        >
          <span
            class="bx bxs-plus-circle float-end fa-2x"
            style="color: #2a3042 !important"
          ></span>
        </button>
      </div>
    </div>

    <table class="table" :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
      <thead>
        <tr
          class="text-light text-center"
          style="background-color: #2a3042 !important"
        >
          <th scope="col">{{ $t("monthlySalaryDetails.table.id") }}</th>
          <th scope="col">{{ $t("monthlySalaryDetails.table.employee") }}</th>
          <th scope="col">{{ $t("monthlySalaryDetails.table.type") }}</th>
          <th scope="col">{{ $t("monthlySalaryDetails.table.salaryitem") }}</th>
          <th scope="col">{{ $t("monthlySalaryDetails.table.month") }}</th>
          <th scope="col">{{ $t("monthlySalaryDetails.table.year") }}</th>
          <th scope="col">{{ $t("monthlySalaryDetails.table.received") }}</th>
          <th scope="col">{{ $t("monthlySalaryDetails.table.created") }}</th>
          <th scope="col">{{ $t("monthlySalaryDetails.table.updated") }}</th>
          <th scope="col">{{ $t("monthlySalaryDetails.table.operations") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(salary, index) in monthly_salary_details" :key="salary">
          <td scope="col">{{ index + 1 }}</td>
          <td scope="col">{{ salary?.emp?.name }}</td>
          <td scope="col">{{ salary.type }}</td>
          <td scope="col">{{ salary?.salary_item?.name }}</td>
          <td scope="col">{{ salary.month }}</td>
          <td scope="col">{{ salary.year }}</td>
          <td scope="col">{{ salary?.received?.split("T")[0] }}</td>
          <td scope="col">{{ salary.created.split("T")[0] }}</td>
          <td scope="col">{{ salary.updated.split("T")[0] }}</td>
          <td>
            <button
              type="button"
              class="btn btn-success me-2 btn-sm"
              @click="editSalary(salary)"
            >
              {{ $t("popups.edit") }}
            </button>
            <a
              class="btn btn-danger btn-sm me-2"
              href="javascript:void(0)"
              role="button"
              @click="deleteMonthlySalaryDetails(salary)"
              >{{ $t("popups.delete") }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <!--end-->
    <!--   Apps  Pagination     -->
    <ul
      v-if="tot_pages > 20"
      class="pagination pagination-rounded justify-content-center mb-2"
    >
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == total_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
    <!--end-->
  </Layout>
</template>
